import req from './request'

//人脸比对
function checkPeople(data){
	return req.post('/app/face/than',data)
}

//发送语音合成获取语音
function getAsr(data){
	return req.get('/app/tts/synthesis',data)
}

//句子对比
function checkAsr(data){
	return req.post('/app/nlp/than',data)
}
//trtc初始化
function trtcInit(data){
	return req.get('/app/trtc/init',data)
}

//trtc初始化
function testRrtcInit(data){
    return req.get('/test/init',data)
}
function testRrtcInit2(data){
    return req.get('/test/init2',data)
}

//获取人脸比对错误码信息
function getErrCode(data){
	return req.get('/app/than/code',data)
}

//获取语音提示语音
function getVoiceSrc(data){
	return req.get('/app/tts/voicePrompt/synthesis',data)
}

function getUser(data){
	return req.post('/saas/user/generate',data)
}
function getUserInit(data){
	return req.post('/saas/user/cert/init',data)
}
function appInit(data){
	return req.get('/app/init',data)
}

//获取语音识别key
function getAsrKey(data){
	return req.get('/app/nlp/getKey',data)
}
export default {
  checkPeople,
  getAsr,
  checkAsr,
  getUser,
  getUserInit,
  appInit,
  trtcInit,
    testRrtcInit,
    testRrtcInit2,
  getErrCode,
  getVoiceSrc,
  getAsrKey
}
