import md5 from 'js-md5'
function getUrlLocal() {
  let url=window.location.href;
  console.log(url,'url')
  let params={}
  if(url.indexOf('?')<0){
    return
  }
  let arr=url.split('?')
  if(arr.length>0){
    let str=arr[1]
    console.log(str,'str')
    if(str.indexOf('&')>0){
      let arr=str.split('&')
      arr.forEach(item=>{
        let tmp=item.split('=')
        params[tmp[0]]=tmp[1]
      })
    }else{
      let arr=str.split('=')
      params[arr[0]]=arr[1]
    }
  }
  return params
}

function dataSerialize(sortObj){
	let strJoin = ''
	for(let key in sortObj){
		strJoin += key + "=" + sortObj[key] + "&"
	}
	return strJoin
}

function sortData(obj){
	if (JSON.stringify(obj) == "{}" || obj == null) {
		return {}
	}
	let key = Object.keys(obj)?.sort()
	let newObj = {}
	for (let i = 0; i < key.length; i++) {
		newObj[key[i]] = obj[key[i]]
	}
	return newObj
}

function paramsFormat(data,key){
	let sortData = this.sortData(data)
	let formatData = this.dataSerialize(sortData)
	let dataStr = formatData + 'key='+key
	dataStr = md5(dataStr)
	
	return dataStr.toUpperCase()
}

export default {
  getUrlLocal,
  dataSerialize,
  sortData,
  paramsFormat
}
