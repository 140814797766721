import Vue from 'vue'
import { Toast } from 'vant';
import axios from 'axios'
axios.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  })

axios.defaults.withCredentials = true;


function post(url,data,headers) {
  let path = Vue.prototype.apiHOST
  url = path + url;
  return new Promise((resolve,reject)=>{
      if(headers && headers['Content-Type']=='application/x-www-form-urlencoded'){
        const params = new URLSearchParams();
        for(let key in data){
          params.append(key,data[key])
        }
        data=params
      }
      axios.post(url,data,{headers: headers||{}}).then(result=> {
        if (result.data.success) {
          resolve(result.data)
        } else {
          // Toast.fail(result.data.message)
          reject(result)
        }
      }).catch(error=> {
        if (error.data.message) {
          // Toast.fail(error.data.message)
        }
      })

  })
}

function get(url,param) {
  let path = Vue.prototype.apiHOST
  url = path + url;
  return new Promise((resolve,reject) => {
    axios.get(url, {params: param}).then(result => {
      if (result.data.success) {
        if (result.data.code == '30110066') {
          reject(result.data)
          return
        }
        resolve(result.data)
      } else {
        // Toast.fail(result.data.message)
        reject(result)
      }
    }).catch(error => {
      if (error.data.message) {
        // Toast.fail(error.data.message)
      }
    })
  })
}
export default {
  post,
  get,
}
