import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	navType:'0'
  },
  mutations: {
	setNavType(state,value){
		state.navType = value
	}
  },
  actions: {
  },
  modules: {
  }
})
