import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
// import './plugins/element.js'
import api from './api'
import util from './utils.js'
import md5 from 'js-md5'
// import vConsole from './static/vconsole.js'
Vue.config.productionTip = false

Vue.prototype.$baseUrl = 'http://127.0.0.1:8660'
Vue.prototype.apiHOST = '/api'
Vue.prototype.api = api
Vue.prototype.util = util
Vue.prototype.$md5 = md5
// Vue.prototype.vConsole = vConsole
Vue.use(Vant);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
